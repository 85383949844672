import React, { useCallback, useState, useEffect } from 'react';
import Header from 'components/header';
import SendOtp from 'components/signUp/sendOtp';
import VerifyOtp from 'components/signUp/verifyOtp';
import useUser from 'src/components/auth/useUser';
import { CUSTOMER_LOGIN, LOGIN } from 'src/formMapper';

const Login = () => {
  const { user, loading } = useUser({ redirectIfFound: '/loan-requirement' });
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [key, setKey] = useState(Math.random().toString(36).substr(2, 5));
  const [data, setData] = useState({});
  const [isKeyboardOpen, setKeyboardOpen] = useState(false);
  const [showOtpSentStatus, setShowOtpSentStatus] = useState(false);
  const [showOtpReSentStatus, setShowOtpReSentStatus] = useState(false);
  const [contactNo, setcontactNo] = useState('');

  useEffect(() => {
    const screenSize = window.innerHeight;
    setcontactNo(window.localStorage.getItem('contactNo'));
    window.addEventListener('resize', () => {
      if (window.innerHeight < screenSize) {
        setKeyboardOpen(true);
      } else {
        setKeyboardOpen(false);
      }
    });
  }, []);

  const onResendOtp = useCallback(() => {
    setKey(Math.random().toString(36).substr(2, 5));
    setShowOtpSentStatus(false);
    setShowOtpReSentStatus(true);
  }, []);

  const onSendOtp = useCallback((values) => {
    setData(values);
    setShowVerifyOtp(true);
    setShowOtpSentStatus(true);
  }, []);

  if (loading) {
    return null;
  }

  if (user && user.contact_number) {
    return null;
  }

  return (
    <>
      <Header />
      <div
        className={`mx-auto bg-right-bottom bg-no-repeat bg-contain sm:bg-60% 2xl:container ${
          isKeyboardOpen ? 'bg-none' : 'sm:bg-login-illustration'
        }`}
      >
        <div className='p-5 mx-auto mt-20 sm:mt-36 lg:mt-40 sm:w-8/12 sm:p-0 h-screen-except-80 sm:h-screen-except-144 lg:h-[75vh]'>
          <h1 className='mt-2 text-4xl font-bold leading-tight tracking-tight sm:text-5xl text-primary'>
            {LOGIN.header.login_text}
          </h1>
          <p className='mt-2 font-medium text-gray text-opacity-90'>{LOGIN.subHeader.subHeader_login_text}</p>
          <div className='max-w-sm mt-12 space-y-5'>
            <SendOtp
              onSuccess={onSendOtp}
              apiUrl='/auth/mobile/'
              redirectUrl='/signup'
              redirectText='SIGNUP HERE'
              contactNo={contactNo}
            />
            {(showOtpSentStatus || showOtpReSentStatus) && (
              <div className='px-[10px] py-2 rounded-full border border-secondary bg-secondary-lightest'>
                <div className='flex items-center'>
                  <div className='flex-shrink-0'>
                    <img className='w-[18px] h-[18px]' src='icons/success-icon.svg' />
                  </div>
                  <div className='ml-2'>
                    <p className='app-text-dm-small text-gray text-opacity-80'>
                      {CUSTOMER_LOGIN.otp.otp} {showOtpSentStatus ? CUSTOMER_LOGIN.otp.sent : CUSTOMER_LOGIN.otp.resent}{' '}
                      {CUSTOMER_LOGIN.otp.successfully}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {showVerifyOtp && <VerifyOtp phone={data.phone} key={key} resendSuccess={onResendOtp} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
